import 'bootstrap/dist/css/bootstrap.min.css';
import '@/lib/style/style.scss';
import '@/lib/style/new-stuff.scss';
import '@/lib/style/dashboard-style.scss';
import 'react-phone-input-2/lib/style.css';
import '@/lib/style/onboarding.scss';
import '@/lib/style/dashboard.scss';
import '@/lib/style/buttons.scss';

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SWRConfig } from 'swr';
import configureStore from '@/lib/redux/configureStore';
import { AppWrapper } from '@/lib/context/useUserContext';
import fetcher from '@/lib/util/fetcher';
import { ToastProvider } from '@/lib/context/useToastContext';
import AppThemeProvider from '@/lib/themes/AppThemeProvider';
import Sentry from '@/lib/util/Sentry';

const store = configureStore();

// This default export is required in a new `pages/_app.js` file.
export default function App({ Component, pageProps }) {
  return (
    <Sentry.ErrorBoundary fallback="An error has occurered!">
      <Provider store={store}>
        <PersistGate loading={null} persistor={store.__PERSISTOR}>
          <AppWrapper>
            <AppThemeProvider>
              <ToastProvider>
                <SWRConfig
                  value={{
                    fetcher,
                  }}
                >
                  <Component {...pageProps} />
                </SWRConfig>
              </ToastProvider>
            </AppThemeProvider>
          </AppWrapper>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}
