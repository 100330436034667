const scaler2 = 1.15; // for dashboard
// 1 or 1.2

const theme = {
  primaryColor: '#237d26',
  hoverColor: '#09410b',
  secondaryColor: '#02295f',
  borderColor: '#D3D3D3',
  darkerBorderColor: '#868686',
  borderRadius: '3px',
  lighterBorderColor: 'rgb(226, 226, 226)',
  mediumWidth: '768px',
  smallWidth: '768px',
  scaler: 1, // for scaling the font-sizes
  titleFontSize: '25px',
  subtitleFontSize: '18px',
  formfieldLabelsFontSize: '18px',
  buttonFontSize: '16px',
  disclaimerFontSize: '13px',
  paragraphFontSize: '16px',

  // TODO: Add px to the end of these sizes
  // 50, 30, 27, 25, 24, 23, 16, 15, 14, 12
  fontSizexl: 50 * scaler2,
  fontSizetitle: 30 * scaler2,
  fontSizeSubtitle1: 27 * scaler2,
  fontSizeSubtitle2: 25 * scaler2,
  fontSizeSubtitle3: 23 * scaler2,
  fontSizeSubtitle4: 20 * scaler2,
  fontSizeP1: 16 * scaler2,
  fontSizeP2: 14 * scaler2,
  fontSizeP3: 12 * scaler2,
  inviteLinkWidth: 600 * scaler2,
  navLinkPadding: 9 * scaler2,
  fontSizeNavbar: 18 * scaler2,

  // MIXINS (called as props.theme.mixins.scale)
  mixins: {
    scale: (scaleFactor) => `transform: scale(${scaleFactor});
    -webkit-transform: scale(${scaleFactor}); /*Chrome & Safari*/
    -moz-transform: scale(${scaleFactor}); /* Firefox */
    -ms-transform: scale(${scaleFactor}); /* Internet Explorer */
    -o-transform: scale(${scaleFactor}); /* Opera */`,
  },
};

export default theme;
