import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Overpass:300,400,500|Dosis:400,700');

  body {
    font-family: "Overpass", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background: #fff;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 300;
    color: #555555; 
  }

  button:focus {
    outline: none;
}
`;

export default GlobalStyle;
